import React       from 'react';
import { graphql } from 'gatsby';
import styled      from '@emotion/styled';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import Display           from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import { findMedia }     from '@interness/web-core/src/components/_helpers';


const SDisplay = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SDisplayItem = styled.div`
  width: ${props => props.width};
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 640px) {
    width: 100%;
  }
  > div {
    padding: 10px 0 0 0;
    margin-bottom: 20px;
  }
  img {
    margin-bottom: 0;
  }
  h4 {
    margin: 0 0 10px 0;
    font-weight: bold;
    text-align: left;
    font-size: 1.4rem;
  }
  .int-subtitle {
    font-weight: bold;
  }
  h5 {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: left;
    margin-top: 10px;
  }
  p {
    text-align: justify;
    font-size: 0.9rem;
  }
  a {
    position: absolute;
    bottom: -42px;
  }
`;


function IndexPage(props) {

  const serviceItems = [
    {
      title: 'Parkgutschein',
      subTitle: 'Bequem einkaufen, kostenlos parken!',
      descr: 'Ab einen Einkaufswert von 5 € erhalten Sie von uns 30 Minuten kostenloses Parken auf den Parkplätzen des ParkCenters am Rathaus.',
      link: {
        to: findMedia(props.data.media.media, 'park-n-shop_aktionsflyer').publicURL,
        text: 'Download Flyer',
        external: true
      }
    },
    {
      title: 'Goldankauf',
      subTitle: 'machen Sie Ihr Gold zu Geld',
      descr: 'Haben Sie alten Schmuck der Ihnen nicht mehr gefällt? Sie wissen nicht wohin damit? Da sind Sie bei uns genau richtig.',
      link: {
        to: '/service',
        text: 'Mehr erfahren',
        external: false
      }
    },
    {
      title: 'Gravurservice',
      subTitle: 'Ganz persönliche Gravuren',
      descr: 'Gravuren von Zinn und anderen Metallen sowie Glasgravuren, auch beliebiger Logos, Schriften und Symbole, erledigen wir gerne für Sie.',
      link: {
        to: '/service',
        text: 'Mehr erfahren',
        external: false
      }
    },
    {
      title: 'Gutscheine',
      subTitle: 'Einfach online kaufen',
      descr: 'Gutscheine von Trendgalerie B2 erfüllen Herzenswünsche. Nutzen Sie unseren kostenlosen Service mit einem Betrag Ihrer Wahl.',
      link: {
        to: 'https://www.marktheidenfeld-live.de/produkt/trendgalerie-b2-gutschein/',
        text: 'Online kaufen',
        external: true
      }
    }
  ];

  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Trendgalerie B2</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>
            Hallo und willkommen bei der Trendgalerie B2 in Marktheidenfeld, der schönsten Stadt in im unterfränkischen Landkreis Main-Spessart.</p>
            <p>Seit mehr als 20 Jahren ist die Trendgalerie B2 ein Begriff für trendige Uhren, traumhaften Schmuck und wundervolle Trauringe und Eheringe.
            Wir freuen uns auf Ihren Besuch in unserem modernen Geschäft in der Bronnbacher Straße 8 in Marktheidenfeld, wo wir uns in entspannter Atmosphäre ausgiebig Zeit für Ihre Wünsche nehmen möchten.<br/>
            Hier können Sie Ihre Lieblingsstücke auswählen, anprobieren und begutachten.<br/>
            In unserem fein ausgewählten Sortiment finden Sie sowohl top aktuellen Schmuck, moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.
            Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. 
            Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.<br/>
            </p>      
            <p>Bis bald in Marktheidenfeld.<br/>
            Ihr Team von Trendgalerie B2</p>

          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Display/>
          <Spacer/>
        </section>
        <Spacer/>
        <section>
          <Heading tag='h2'>Unser Service für Sie</Heading>
          <SDisplay>
            {serviceItems.map(item => (
              <SDisplayItem width={'22%'} key={item.title}>
                <div>
                  <h4>{item.title}</h4>
                  <p className='int-subtitle'>{item.subTitle}</p>
                  {/*<img src={item.image} alt={item.title}/>*/}
                  <p dangerouslySetInnerHTML={{ __html: item.descr }}/>
                  <Link to={item.link.to} external={item.link.external}>{item.link.text}</Link>
                </div>
              </SDisplayItem>
            ))}
          </SDisplay>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    media: directusMediaCollection(name: {eq: "info-flyer"}) {
      media {
        file {
          localFile {
            name
            publicURL
          }
        }
      }
    }
  }
`;
